import React from "react"
// import styled from "styled-components"
// import { Link } from "gatsby"
// import MainImage from "../components/main-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentImage from "../components/content-image"

import "../css/style.css";


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="container">
        <div className="row">
            <article className="col-12 col-sm-12 col-md-12 col-lg-7 mb-5 mb-sm-5">
                
                <ContentImage />

                <div className="content">
                    <p>
                        Mein Team und ich bieten Ihnen eine vielseitige Auswahl an neuesten Frisuren und Stylingtrends
                        für Damen, Herren und Kinder. Durch eine individuelle Beratung und langjährige Erfahrung entwickeln
                        wir zusammen den perfekten Look für Sie. Qualitativ hochwertige Produkte und Materialien sind für 
                        uns eine Selbstverständlichkeit. Genießen Sie bei uns eine Atmosphäre zum Wohlfühlen und profitieren
                        Sie von unserer Kompetenz und Erfahrung. Bei uns bekommen Sie:
                    </p>
                    <ul>
                        <li>individuelle Beratung </li>
                        <li>aktuelle Haarschnitte für Herren / Damen und Kinder</li>
                        <li>kreative Farb-und Strähnentechniken </li>
                        <li>Umformungen </li>
                        <li>klassische und moderne Hochsteckfrisruren </li>
                        <li>Hochzeitsfrisuren </li>
                        <li>ausgewählte, qualitativ hochwertige Produkte </li>
                    </ul>
                    <p>
                        Kommen Sie zu uns und lassen Sie sich von uns verwöhnen. Wir freuen uns auf Ihren Besuch.
                    </p>
                    <p>
                        Ihr Team vom Haarkonzept Krauchenwies
                    </p>
                </div>
            </article>
            
            <aside className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="opening-times mb-5 mb-sm-0">
                            <h2>Öffnungszeiten</h2>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Mo</td>
                                        <td>Ruhetag</td>
                                    </tr>
                                    <tr>
                                        <td>Di</td>
                                        <td>9- 18 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td>Mi</td>
                                        <td>9- 18 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td>Do</td>
                                        <td>9 - 20 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td>Fr</td>
                                        <td>9 - 20 Uhr</td>
                                    </tr>
                                    <tr>
                                        <td>Sa</td>
                                        <td>geschlossen</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="contact">
                            <h2>So finden Sie uns</h2>
                            <p>
                                <strong>haarkonzept</strong><br/>
                                Inh. Angela Wiedmann<br/>
                                Haupstraße 4<br/>
                                72505 Krauchenwies<br/>
                            </p>

                            <p>
                                <strong>Telefon:</strong> +49 7576 92 98 82<br/>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="cta">
                            <h2>Online Terminbuchung</h2>
                            <p>Ab sofort können Sie sich Ihren Termin ganz einfach selbst bei uns buchen. Einfach auf den Link klicken und Termin festlegen - Fertig!</p>
                            <a href="https://www.studiobookr.com/haarkonzept-41497?dcc=1" target="_blank" rel="noreferrer" className="btn btn-primary">Termin online buchen</a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="map">
                            <>
                                <iframe
                                    title="map"
                                    width="100%"
                                    height="300"
                                    style={{ border: "none", margin: 0 }}
                                    src="https://www.openstreetmap.org/export/embed.html?bbox=9.249278604984285%2C48.01792119845846%2C9.251531660556795%2C48.01919680526407&amp;layer=mapnik&amp;marker=48.018558108755236%2C9.250405132770538"
                                ></iframe>
                                <small>
                                    <a
                                        href="https://www.openstreetmap.org/?mlat=48.00564&amp;mlon=9.65599#map=19/48.00564/9.65599"
                                    >
                                        Größere Karte anzeigen
                                    </a>
                                </small>
                            </>
                        </div>
                    </div>
                </div>
            </aside>
        </div>   
    </div>

  </Layout>
)

export default IndexPage
